import { useState, memo } from 'react'
import { makeStyles } from '@mui/styles'
import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'

import Logo from './Logo'
import PhoneContact from './PhoneContact'
import PhoneContactBox from './PhoneContactBox'
import theme from '../../theme'

const useStyles = makeStyles<Theme, { isBankList: boolean }>({
  appBar: {
    backgroundColor: '#fff !important',
    height: '80px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    maxWidth: ({ isBankList }) =>
      isBankList ? theme.breakpoints.values.xl : theme.breakpoints.values.md,
  },
  containerLarge: {
    justifyContent: 'space-between',
  },
})

const Header = ({ isBankList = false }) => {
  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('xs'))

  const { appBar, container, containerLarge } = useStyles({ isBankList })

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <AppBar elevation={0} className={appBar} position='static'>
        <Container
          className={`${container} ${!isMobile && containerLarge}`}
          maxWidth='md'
        >
          <Logo />
          <PhoneContact onClick={() => setOpen(!isOpen)} />
        </Container>
      </AppBar>
      <PhoneContactBox isOpen={isOpen} />
    </>
  )
}

export default memo(Header)
